import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  referralData: null,
};

export const getReferral = createAsyncThunk(
  "referral/getReferral",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/referrals`);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers: {
    [getReferral.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getReferral.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.referralData = action.payload?.data?.data;
    },
    [getReferral.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default referralSlice.reducer;
