import axios from "axios";

const PRODUCTION = process.env.REACT_APP_PRODUCTION;
const BASE_URL =
  PRODUCTION === "true"
    ? process.env.REACT_APP_APIURL_LIVE
    : process.env.REACT_APP_STAGING_APIURL;

let token = "";
export const getAxiosInstance = async () => {
  try {
    token = window.localStorage.getItem("USER_ACCESS_TOKEN");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
