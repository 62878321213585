import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  uniqTokenData: null,
};

export const getUniqToken = createAsyncThunk(
  "uniqtoken/getUniqToken",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/uniqtoken`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const uniqTokenSlice = createSlice({
  name: "uniqtoken",
  initialState,
  reducers: {},
  extraReducers: {
    [getUniqToken.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getUniqToken.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.uniqTokenData = action.payload?.data?.data;
    },
    [getUniqToken.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default uniqTokenSlice.reducer;
