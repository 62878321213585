import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  singleCareerData: null,
};

export const careerDetails = createAsyncThunk(
  "careers/detailPage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`single_job?slug= ${params}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const careerDetailSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {},
  extraReducers: {
    [careerDetails.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [careerDetails.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.singleCareerData = action.payload?.data;
    },
    [careerDetails.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default careerDetailSlice.reducer;
