import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: null,
  message: null,
  formKey: null,
  brokerStatus: "idle",
  contactStatus: "idle",
  resumeStatus: "idle",
  applyFormStatus: "idle",
  clientStatus: "idle",
  subscribeStatus: "idle",
  newsSubscriptionStatus: "",
  newsSubscriptionData: null,
  unsubscribeStatus: "idle",
};

export const getFormKey = createAsyncThunk(
  "form/getFormKey",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`get_nonce`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const contactForm = createAsyncThunk(
  "form/contactForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`contact_submit`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resumeForm = createAsyncThunk(
  "form/resumeForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`career_form_submit`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const applyForm = createAsyncThunk(
  "form/applyForm",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`career_form_submit`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registrationBroker = createAsyncThunk(
  "registration/broker",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`broker-registration`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registrationClient = createAsyncThunk(
  "registration/client",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`client-registration`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const subscribeNews = createAsyncThunk(
  "form/subscribeNews",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`subscribe`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const newsSubscription = createAsyncThunk(
  "news/newsSubscription",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `newsletter/activate?z=${params.subscriptionSlug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const unsubscribe = createAsyncThunk(
  "unsubscribe",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`unsubscribe`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {},
  extraReducers: {
    [getFormKey.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFormKey.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.formKey = action?.payload?.data;
    },
    [getFormKey.rejected]: (state, action) => {
      state.status = "failed";
    },

    [contactForm.pending]: (state, action) => {
      state.contactStatus = "loading";
      state.message = null;
    },
    [contactForm.fulfilled]: (state, action) => {
      state.contactStatus = "succeeded";
    },
    [contactForm.rejected]: (state, action) => {
      state.contactStatus = "failed";
    },

    [resumeForm.pending]: (state, action) => {
      state.resumeStatus = "loading";
      state.message = null;
    },
    [resumeForm.fulfilled]: (state, action) => {
      state.resumeStatus = "succeeded";
    },
    [resumeForm.rejected]: (state, action) => {
      state.resumeStatus = "failed";
    },

    [applyForm.pending]: (state, action) => {
      state.applyFormStatus = "loading";
      state.message = null;
    },
    [applyForm.fulfilled]: (state, action) => {
      state.applyFormStatus = "succeeded";
    },
    [applyForm.rejected]: (state, action) => {
      state.applyFormStatus = "failed";
    },
    [registrationBroker.pending]: (state, action) => {
      state.brokerStatus = "loading";
      state.message = null;
    },
    [registrationBroker.fulfilled]: (state, action) => {
      state.brokerStatus = "succeeded";
    },
    [registrationBroker.rejected]: (state, action) => {
      state.brokerStatus = "failed";
    },

    [registrationClient.pending]: (state, action) => {
      state.clientStatus = "loading";
      state.message = null;
    },
    [registrationClient.fulfilled]: (state, action) => {
      state.clientStatus = "succeeded";
    },
    [registrationClient.rejected]: (state, action) => {
      state.clientStatus = "failed";
    },

    [subscribeNews.pending]: (state, action) => {
      state.subscribeStatus = "loading";
      state.message = null;
    },
    [subscribeNews.fulfilled]: (state, action) => {
      state.subscribeStatus = "succeeded";
    },
    [subscribeNews.rejected]: (state, action) => {
      state.subscribeStatus = "failed";
    },

    [newsSubscription.pending]: (state, action) => {
      state.newsSubscriptionStatus = "loading";
      state.message = null;
    },
    [newsSubscription.fulfilled]: (state, action) => {
      state.newsSubscriptionStatus = "succeeded";
      state.newsSubscriptionData = action.payload?.data;
    },
    [newsSubscription.rejected]: (state, action) => {
      state.newsSubscriptionStatus = "failed";
    },
    [unsubscribe.pending]: (state, action) => {
      state.unsubscribeStatus = "loading";
      state.message = null;
    },
    [unsubscribe.fulfilled]: (state, action) => {
      state.unsubscribeStatus = "succeeded";
    },
    [unsubscribe.rejected]: (state, action) => {
      state.unsubscribeStatus = "failed";
    },
  },
});

export default formSlice.reducer;
