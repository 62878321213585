import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  cryptoData: null,
};

export const getCrypto = createAsyncThunk(
  "crypto/getCrypto",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/${params.cryptoSlug}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {},
  extraReducers: {
    [getCrypto.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCrypto.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.cryptoData = action.payload?.data;
    },
    [getCrypto.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default cryptoSlice.reducer;
