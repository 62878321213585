import { combineReducers } from "@reduxjs/toolkit";
import aboutUsSlice from "./slices/aboutUsSlice";
import careersSlice from "./slices/careersSlice";
import educareSlice from "./slices/educareSlice";
import faqSlice from "./slices/faqSlice";
import footerSlice from "./slices/footerSlice";
import formSlice from "./slices/formSlice";
import homeSlice from "./slices/homeSlice";
import marketSlice from "./slices/marketSlice";
import liveSlice from "./slices/liveSlice";
import mediaSlice from "./slices/mediaSlice";
import menuSlice from "./slices/menuSlice";
import privacyPolicySlice from "./slices/privacyPolicySlice";
import singleBlogSlice from "./slices/singleBlogSlice";
import referralSlice from "./slices/referralSlice";
import membershipSlice from "./slices/membershipSlice";
import uniqTokenSlice from "./slices/uniqTokenSlice";
import cryptoSlice from "./slices/cryptoSlice";
import glossarySlice from "./slices/glossarySlice";
import supportCountriesSlice from "./slices/supportCountriesSlice";
import CareerDetailsSlice from "./slices/CareerDetailsSlice";
import newsLetterSlice from "./slices/newsLetterSlice";
import legalSlice from "./slices/legalSlice";

const rootReducer = combineReducers({
  home: homeSlice,
  menu: menuSlice,
  about: aboutUsSlice,
  media: mediaSlice,
  singleBlog: singleBlogSlice,
  privacy: privacyPolicySlice,
  form: formSlice,
  live:liveSlice,
  careers: careersSlice,
  careerDetails: CareerDetailsSlice,
  faq: faqSlice,
  educare: educareSlice,
  market: marketSlice,
  footer: footerSlice,
  referral: referralSlice,
  membership: membershipSlice,
  uniqToken: uniqTokenSlice,
  crypto:cryptoSlice,
  glossary:glossarySlice,
  supportedCountries:supportCountriesSlice,
  news:newsLetterSlice,
  legal:legalSlice
});

export default rootReducer;
