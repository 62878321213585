import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  careersData: null,
  jobOpeningData: null,
};

export const getCareers = createAsyncThunk(
  "careers/getCareers",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/career`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const jopOpeningData = createAsyncThunk(
  "careers/jobOpening",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`job-openings`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const careersSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {},
  extraReducers: {
    [getCareers.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCareers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.careersData = action.payload?.data;
    },
    [getCareers.rejected]: (state, action) => {
      state.status = "failed";
    },

    [jopOpeningData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [jopOpeningData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.jobOpeningData = action.payload?.data?.data?.details;
    },
    [jopOpeningData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default careersSlice.reducer;
