import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  educareDetails: null,
  educareTopicsList: null,
  educareTopicsSingle: null,
};

export const getEducare = createAsyncThunk(
  "educare/getEducare",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`educare/page`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEducareTopic = createAsyncThunk(
  "educare/getEducareTopic",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `educare/posts?page=${params.page}&perpage=${params.per_page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEducareTopicSingle = createAsyncThunk(
  "educare/getEducareTopicSingle",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`educare/posts?slug=${params.slug}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const educareSlice = createSlice({
  name: "educare",
  initialState,
  reducers: {},
  extraReducers: {
    [getEducare.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getEducare.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.educareDetails = action.payload?.data;
    },
    [getEducare.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getEducareTopic.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getEducareTopic.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.educareTopicsList = action.payload?.data?.data?.details;
    },
    [getEducareTopic.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getEducareTopicSingle.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getEducareTopicSingle.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.educareTopicsSingle = action.payload?.data;
    },
    [getEducareTopicSingle.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default educareSlice.reducer;
