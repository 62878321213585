import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  privacyData: null,
};

export const getPrivacy = createAsyncThunk(
  "privacy/getPrivacy",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `page?slug=${params.slug}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const privacyPolicySlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {},
  extraReducers: {
    [getPrivacy.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getPrivacy.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.privacyData = action.payload?.data?.data;
    },
    [getPrivacy.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default privacyPolicySlice.reducer;
