import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  liveData: null,
};

export const getLive = createAsyncThunk(
  "live/getLive",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`market_values`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {},
  extraReducers: {
    [getLive.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getLive.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.liveData = action.payload?.data;
    },
    [getLive.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default liveSlice.reducer;
