import React, { useRef, useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Style from "./Menu.module.scss";
import Assets from "../Assest";
import { useDimensions } from "../../../logic/useDimension";
import { Link } from "react-router-dom";
import { useGetHoverData } from "../../../logic/useGetHoverData";
import { useLocation, useHistory } from "react-router-dom";

const Menu = () => {
  let history = useHistory();
  const location = useLocation();
  const { fetchOnMouseHover } = useGetHoverData();
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useDimensions();
  const [isHeadSmall, setHeadSmall] = useState(false);
  const openClasses = isOpen ? Style.isOPen : "";
  const navMobile = useRef();
  const mobileNavRef = useRef();
  const dropRef = useRef();
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    if (width >= 992) {
      setShow(true);
    } else {
      setShow(!show);
    }
    setTimeout(() => {
      if (dropRef.current) {
        dropRef.current.classList.add("add");
      }
    }, 100);
  };
  const handleSCroll = () => {
    window.scrollY > 50 ? setHeadSmall(true) : setHeadSmall(false);
  };
  window.addEventListener("scroll", handleSCroll);
  const headerClasses = isHeadSmall ? Style.headerSlim : Style.headerInitial;

  const hideDropdown = (e) => {
    setTimeout(() => {
      if (dropRef.current) {
        dropRef.current.classList.remove("add");
      }
    }, 100);
    setTimeout(() => {
      setShow(false);
    }, 200);
  };
  const handleClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setShow(false);
      setIsOpen(false);
    }
    if (isOpen) {
      document.querySelector("body").classList.remove("is-open");
    } else {
      document.querySelector("body").classList.add("is-open");
    }
  };

  return (
    <>
      <header className={`${Style.header} fixed-top ${headerClasses}`}>
        {width >= 992 ? (
          <Navbar collapseOnSelect expand="lg" bg="secondary" variant="dark">
            <Container>
              <Link className="navbar-brand" to="/">
                <div className={Style.logo_wrap}>
                  <img
                    src={Assets.logo}
                    className={Style.logo}
                    alt="Unicoindcx"
                    title="unicoin dcx"
                  />
                </div>
              </Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Link
                    onMouseEnter={() => {
                      fetchOnMouseHover("/markets");
                    }}
                    className={` nav-link ${
                      location.pathname == "/markets" ? `active` : ""
                    }`}
                    to="/markets"
                  >
                    Markets
                  </Link>
                  <NavDropdown
                    className={
                      location.pathname.substring(1, 6) == "coins" &&
                      Style.cypto
                    }
                    title="Buy Cryptos"
                    ref={dropRef}
                    id="basic-nav-dropdown"
                    alignCenter
                    show={show}
                    onMouseEnter={showDropdown}
                    onMouseLeave={hideDropdown}
                  >
                    <Link
                      to="/coins/buy-bitcoin"
                      className={` dropdown-item ${
                        location.pathname == "/coins/buy-bitcoin"
                          ? `active`
                          : ""
                      }`}
                    >
                      Buy Bitcoin
                    </Link>
                    <Link
                      to="/coins/buy-ethereum"
                      className={` dropdown-item ${
                        location.pathname == "/coins/buy-ethereum"
                          ? `active`
                          : ""
                      }`}
                    >
                      Buy Ethereum
                    </Link>
                    <Link
                      to="/coins/buy-bitcoin-cash"
                      className={` dropdown-item ${
                        location.pathname == "/coins/buy-bitcoin-cash"
                          ? `active`
                          : ""
                      }`}
                    >
                      Buy Bitcoin Cash
                    </Link>
                    <Link
                      to="/coins/buy-litecoin"
                      className={` dropdown-item ${
                        location.pathname == "/coins/buy-litecoin"
                          ? `active`
                          : ""
                      }`}
                    >
                      Buy Litecoin
                    </Link>
                  </NavDropdown>
                  <Link
                    onMouseEnter={() => {
                      fetchOnMouseHover("/buy-uniq-token");
                    }}
                    className={` nav-link ${
                      location.pathname == "/buy-uniq-token" ? `active` : ""
                    }`}
                    to="/buy-uniq-token"
                  >
                    UNIQ Token
                  </Link>
                  <Link
                    onMouseEnter={() => {
                      fetchOnMouseHover("/referrals");
                    }}
                    className={` nav-link ${
                      location.pathname == "/referrals" ? `active` : ""
                    }`}
                    to="/referrals"
                  >
                    Referrals
                  </Link>
                  <Link
                    onMouseEnter={() => {
                      fetchOnMouseHover("/contact-us");
                    }}
                    className={` nav-link ${
                      location.pathname == "/contact-us" ? `active` : ""
                    }`}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>
                </Nav>
                <Nav>
                  <a
                    href="https://online.unicoindcx.com/login"
                    className="btn btn-sm btn-transparent"
                    target="_blank"
                  >
                    Sign In
                  </a>
                  <Link
                    to="/signup"
                    className="button button-sm button-primary"
                  >
                    <span>Sign Up</span>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        ) : (
          <>
            <div
              className={`${Style.header_mobile_nav} ${
                isOpen && Style.menu_open
              }`}
              ref={mobileNavRef}
            >
              <div className={`container ${Style.container}`}>
                <div
                  className={Style.logo_wrap}
                  onClick={() => {
                    if (isOpen) {
                      document
                        .querySelector("body")
                        .classList.remove("is-open");
                      setIsOpen(false);
                      setShow(false);
                    }
                    history.push("/");
                  }}
                >
                  <img src={Assets.logo} className={Style.logo} alt="logo" />
                </div>
                <div className={Style.navIcon} onClick={handleClick}>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>

            <div
              className={`${Style.MobileMenu} ${openClasses}`}
              ref={navMobile}
            >
              <div className={Style.round_header}>
                <img src={Assets.round_header} className="w-100" alt="Market" />
              </div>
              <nav className="">
                <ul>
                  <li>
                    <Link
                      to="/markets"
                      onClick={handleClick}
                      className={Style.menu_link}
                    >
                      <span>Markets</span>
                    </Link>
                  </li>
                  <li
                    className={`${Style.menu_dropdown} ${show && Style.show}`}
                  >
                    <a
                      className={Style.menu_link}
                      show={show}
                      onClick={showDropdown}
                    >
                      <span>Buy Cryptos</span>
                    </a>
                    <ul>
                      <li>
                        <Link onClick={handleClick} to="/coins/buy-bitcoin">
                          Buy Bitcoin
                        </Link>
                      </li>
                      <li>
                        <Link to="/coins/buy-ethereum" onClick={handleClick}>
                          Buy Ethereum
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coins/buy-bitcoin-cash"
                          onClick={handleClick}
                        >
                          Buy Bitcoin Cash
                        </Link>
                      </li>
                      <li>
                        <Link to="/coins/buy-litecoin" onClick={handleClick}>
                          Buy Litecoin
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="/buy-uniq-token"
                      onClick={handleClick}
                      className={Style.menu_link}
                    >
                      <span>UNIQ Token</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/referrals"
                      onClick={handleClick}
                      className={Style.menu_link}
                    >
                      <span>Referrals</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      onClick={handleClick}
                      className={Style.menu_link}
                    >
                      <span>Contact Us</span>
                    </Link>
                  </li>
                </ul>

                <div className={`${Style.btn_wrap} mt-auto`}>
                  <a
                    href="https://online.unicoindcx.com/login"
                    target="_blank"
                    className="btn btn-outline-light w-100"
                  >
                    Sign In
                  </a>
                  <Link
                    to="/signup"
                    onClick={handleClick}
                    className="btn btn-primary w-100"
                  >
                    Sign Up
                  </Link>
                </div>
              </nav>
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Menu;
