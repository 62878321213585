import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  singleBlog: null,
};

export const getSingleBlog = createAsyncThunk(
  "singleBlog/getSingleBlog",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `single-media-and-announcement?pid=${params.pId}&cat_id=${params.catId}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const singleBlogSlice = createSlice({
  name: "singleBlog",
  initialState,
  reducers: {},
  extraReducers: {
    [getSingleBlog.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSingleBlog.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.singleBlog = action.payload?.data;
    },
    [getSingleBlog.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default singleBlogSlice.reducer;
