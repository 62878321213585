import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  statusFAQData: "idle",
  error: null,
  message: null,
  newsCategoryData: [],
  categoryStatus: "idle",
  newsData: {},
  selectedNews: "",
  singleNews: null,
};

export const getNewsCategory = createAsyncThunk(
  "news/getNewsCategory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`newsletter_category`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNewsData = createAsyncThunk(
  "news/getNewsData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/newsletter-list?${params.newsSlug}=true&posts_per_page=${params.per_page}&page=${params.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleNews = createAsyncThunk(
  "news/getSingleNews",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`newsletter-list?id=${params.singleNews}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const newsLetterSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    handleSelectedNewsCategory: (state, action) => {
      state.selectedNews = action.payload;
    },
  },
  extraReducers: {
    [getNewsCategory.pending]: (state, action) => {
      state.categoryStatus = "loading";
      state.message = null;
    },
    [getNewsCategory.fulfilled]: (state, action) => {
      state.categoryStatus = "succeeded";
      state.newsCategoryData = action.payload?.data?.data?.details;
    },
    [getNewsCategory.rejected]: (state, action) => {
      state.categoryStatus = "failed";
    },

    [getNewsData.pending]: (state, action) => {
      state.statusFAQData = "loading";
      state.message = null;
    },
    [getNewsData.fulfilled]: (state, action) => {
      state.statusFAQData = "succeeded";
      state.newsData[state.selectedNews] = action.payload?.data?.data?.details;
    },
    [getNewsData.rejected]: (state, action) => {
      state.statusFAQData = "failed";
    },

    [getSingleNews.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSingleNews.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.singleNews = action.payload?.data?.data;
    },
    [getSingleNews.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const { handleSelectedNewsCategory } = newsLetterSlice.actions;

export default newsLetterSlice.reducer;
